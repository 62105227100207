.slick-slider {
  position: relative;
}

.slick-prev,
.slick-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  cursor: pointer;
  width: 40px; /* Adjust arrow width */
  height: 40px; /* Adjust arrow height */
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  border-radius: 50%; /* Make the arrows circular */
  display: flex;
  justify-content: center;
  align-items: center;
  color: white; /* Arrow icon color */
}

.slick-prev {
  left: 10px;
}

.slick-next {
  right: 10px;
}

/* Hover effect */
.slick-prev:hover,
.slick-next:hover {
  background-color: rgba(0, 0, 0, 0.7); /* Darken the background on hover */
}

/* ImageGallery.css */

.image-gallery-container {
  width: 50%;
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
}

.image-slide {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centers items horizontally */
}

.fixed-image-size {
  width: 100%; /* Make sure the images take the full width of their parent */
}

.image-container {
  position: relative;
  margin-right: 10px;
}

.delete-icon {
  position: absolute;
  top: 3px;
  right: 3px;
  z-index: 1;
  cursor: pointer;
  width: 20px;
  height: 30px;
  background-color: rgba(255, 255, 255, 0.825);
  color: rgb(0, 0, 0);
}

.custom-modal-content {
  background-color: #fff0;
  border: 0;
  /* Other custom styles for the modal content */
}
